import { lauderLarken_axios } from "@/plugins/axios";
import axios from "@/plugins/axios";
const parsedClient = client => client && client.replace("'", "''''");
let source = axios.CancelToken.source();
export default {
  login({ username, password }) {
    return lauderLarken_axios.post("verifylogin", {
      username,
      password
    });
  },
  getDash(client) {
    let now = new Date().getFullYear();
    return lauderLarken_axios.post("getdash", {
      years: [now - 2, now - 1, now],
      client: parsedClient(client)
    });
  },
  getPayStat(client) {
    return lauderLarken_axios.post("getpaystat", {
      client: parsedClient(client)
    });
  },
  getPayTen(client) {
    return lauderLarken_axios.post("getpaylasttenyears", {
      client: parsedClient(client)
    });
  },
  validateToken() {
    return lauderLarken_axios.get("validatetoken");
  },
  getPaymentData() {
    return lauderLarken_axios.get("getpayments");
  },
  getAssociationsData() {
    return lauderLarken_axios.get("getassociations");
  },
  getReports(start = 0, count = 0) {
    return lauderLarken_axios.post(
      "reportCollections",
      {
        count,
        start
      },
      {
        cancelToken: source.token
      }
    );
  },
  getClosedPayments(start = 0, count = 150) {
    return lauderLarken_axios.post(
      "closedPayArr",
      {
        count,
        start
      },
      {
        cancelToken: source.token
      }
    );
  },
  getClosedAssociations(start = 0, count = 150) {
    return lauderLarken_axios.post(
      "closedAssArr",
      {
        count,
        start
      },
      {
        cancelToken: source.token
      }
    );
  },
  getCountOfData() {
    return lauderLarken_axios.get("countOfClosedReports");
  },
  getProfile() {
    return lauderLarken_axios.get("getprofile");
  },
  getInformationData(token) {
    return lauderLarken_axios.post("getinformationdata", {
      AssId: token
    });
  },
  logout() {
    return lauderLarken_axios.get("logoutUser");
  },
  getContacts() {
    return lauderLarken_axios.get("getcontacts", {
      cancelToken: source.token
    });
  },
  getNames() {
    return lauderLarken_axios.get("getClientsName");
  },
  cancel() {
    source.cancel("Operation canceled by the user.");
    source = axios.CancelToken.source();
  }
};
