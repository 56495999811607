<template>
  <v-autocomplete
    label="Select association"
    v-model="selectedAss"
    cache-items
    item-text="Client_Associations"
    item-value="Client_Associations"
    return-object
    light
    dense
    filled
    solo
    v-bind="$attrs"
    loader-height="3.5"
    :items="withSort | rearrangeAss"
    hide-details
    class="mb-1 mw"
  />
</template>

<script>
import { mapGetters } from "vuex";
import storage from "@/storage";
import requests from "@/requests";
export default {
  name: "disAssocDrop",
  data: () => ({
    names: []
  }),
  computed: {
    selectedAss: {
      get() {
        return this.ass;
      },
      set(value) {
        storage.setSelectedAss(value);
        this.$store.commit("lauderSetSelectedAss", value);
      }
    },
    withSort() {
      let arr = this.names;
      if (arr && arr.length > 0) {
        arr = [
          { Client_Associations: "All Associations", AllAss: true }
        ].concat(arr);
        return arr;
      }
      return [];
    },
    ...mapGetters({
      ass: "lauderGetSelectedAss",
      db: "laudergetdb"
    })
  },
  mounted() {
    this.startData();
  },
  methods: {
    async fetchNames() {
      try {
        const result = await requests.getNames();
        const data = result.data.data;
        await storage.setAllClients(data, this.db);
        this.names = data;
      } catch (e) {
        //todo appear error here
        await this.fetchNames();
        console.log(e);
      }
    },
    async startData() {
      const data = await storage.getAllClients(this.db);
      if (!data || data.length === 0) {
        await this.fetchNames();
      } else {
        this.names = data;
      }
    }
  },
  props: {
    // loading: {
    //   type: Boolean,
    //   default: false
    // },
    filled: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: "primary"
    },
    background: {
      type: String
    },
    dense: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
.mw {
  max-width: 400px;
}
</style>
